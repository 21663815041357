import * as React from "react"
import { Link } from "gatsby"
import { Col, Row } from "react-bootstrap"
import {
  FaMusic,
  FaNewspaper,
  // FaPenFancy,
  FaPhotoVideo,
  FaShoppingCart,
  FaUserTie,
  FaVideo,
} from "react-icons/fa"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const navLinks = [
  {
    title: "Music",
    icon: <FaMusic className="nav-icon" />,
    url: "/music/",
  },
  {
    title: "Videos",
    icon: <FaVideo className="nav-icon" />,
    url: "/videos/",
  },
  {
    title: "Magazine",
    icon: <FaNewspaper className="nav-icon" />,
    url: "/magazine/",
  },
  {
    title: "Gallery",
    icon: <FaPhotoVideo className="nav-icon" />,
    url: "/gallery/",
  },
  // {
  //   title: "Blog",
  //   icon: <FaPenFancy className="nav-icon" />,
  //   url: "/blog/",
  // },
  {
    title: "About",
    icon: <FaUserTie className="nav-icon" />,
    url: "/about/",
  },
  {
    title: "Shop",
    icon: <FaShoppingCart className="nav-icon" />,
    url: "https://itsrenowned.square.site/",
  },
]

const Home = ({ location }) => {
  return (
    <>
      <Layout location={location}>
        <Seo title={"Home"} />
        <div className="d-flex flex-column justify-content-center flex-fill">
          <Hero />
          <Row
            className="align-items-center"
            style={{ minHeight: "25vh" }}
            as="nav"
          >
            {navLinks.map((navLink, i) => (
              <Col key={i} xs="6" md="4" className="mb-3">
                {navLink.url.includes("https") ? (
                  <a
                    href={navLink.url}
                    className="hvr-bob"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {navLink.icon}
                    <br />
                    <span className="nav-a">{navLink.title}</span>
                  </a>
                ) : (
                  <Link to={navLink.url} className="hvr-bob">
                    {navLink.icon}
                    <br />
                    <span className="nav-a">{navLink.title}</span>
                  </Link>
                )}
              </Col>
            ))}
          </Row>
        </div>
      </Layout>
    </>
  )
}

export default Home
