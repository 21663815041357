import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Image } from "react-bootstrap"

import { caret, hero } from "./hero.module.css"

// https://www.digitalocean.com/community/tutorials/react-countdown-timer-react-hooks
const calculateTimeLeft = () => {
  let difference = +new Date(`12/05/2023`) - +new Date()

  let timeLeft = {}

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
  }

  return timeLeft
}

const Hero = () => {
  const [timeLeft, setTimeLeft] = React.useState(0)
  const { site } = useStaticQuery(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const { title } = site.siteMetadata

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    )
  })

  return (
    <div className={hero}>
      <div className={caret} />
      <div className="position-relative">
        <Image
          fluid
          className="mb-4"
          src={"/assets/renowned-planets.png"}
          alt="Logo"
        />
        <h1 className="glow">
          <Link to="/mercury/">{title}</Link>
        </h1>
        <p className="mt-3 mb-5">
          {timerComponents.length ? (
            timerComponents
          ) : (
            <span>No effort, no return.</span>
          )}
        </p>
      </div>
    </div>
  )
}

export default Hero
